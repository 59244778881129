@import url('./root.css');

.body {
  height: 100dvh;
  margin: 0;
  padding: 0;
  text-align: justify;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.one-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
}
th,
td {
  text-align: left;
  padding: 8px;
}
th {
  background-color: #f2f2f2;
}

/* main {
  margin-top: 4rem;
} */

/*  */

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

/* h6,
.h6,
h5,
.h5,*/
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: 'Newsreader', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 600;
  line-height: 1.2;
  color: var(--bs-primary);
  text-align: left;
}

form input,
form select,
textarea {
  margin-bottom: 1rem;
}

.card-body h5 {
  font-family: unset;
  text-align: unset;
}

textarea {
  min-height: 12rem !important;
}

textarea .project-body {
  min-height: 45rem !important;
}

svg {
  margin-top: -3px;
}
