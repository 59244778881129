.project-details {
  display: flex;
  flex-wrap: wrap;
}

.project-details p {
  background-color: var(--bs-gray-200);
  padding: 6px 12px;
  width: fit-content;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}

.project-details p:hover {
  background-color: var(--bs-primary);
  color: var(--bs-white);
}

.project-details p:hover svg {
  color: var(--bs-white);
}

.project-details p svg {
  margin-right: 5px;
  font-size: 16px;
  color: var(--bs-primary);
  margin-top: -3px;
}

.my-bread-crumb {
  display: block;
  text-decoration: none;
  color: var(--bs-gray-600);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2rem;
}

.my-bread-crumb svg {
  margin-right: 5px;
  margin-top: -3px;
}

.my-bread-crumb:hover {
  color: var(--bs-primary);
}

section {
  margin-top: 3rem;
  background-color: var(--bs-gray-100);
}

section h1,
section h2,
section h3,
section h4,
section h5,
section h6 {
  color: black;
  font-family: unset;
}
