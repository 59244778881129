.nav-link {
  color: var(--bs-black);
}

.order-details {
  font-weight: 600;
  text-align: left;
}

.order-details p {
  margin: 0;
}
